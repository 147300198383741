.preloader {
    display: flex;
    align-items: center;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.6) !important;
    @include transition(height .3s);

    .loader-element {

        @include transition(spin .3s linear infinite);
        -webkit-animation:spin 3s linear infinite;
        -moz-animation:spin 3s linear infinite;
        animation:spin 3s linear infinite;
    }

    &.show {
        height: 0;

        .loader-element {
            display: none !important;
        }
    }
}
/*
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
*/

@-moz-keyframes spin { 50% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 50% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 50% { -webkit-transform: rotate(360deg); transform:rotateY(80deg); } }
